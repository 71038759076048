import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import regeneratorRuntime from "/home/vsts/work/1/s/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import JWTDecode from "jwt-decode";
import { DateTime } from "luxon";
import nookies, { destroyCookie } from "nookies";
var __awaiter = this && this.__awaiter || function(thisArg, _arguments, P, generator) {
    var adopt = function adopt(value) {
        return _instanceof(value, P) ? value : new P(function(resolve) {
            resolve(value);
        });
    };
    return new (P || (P = Promise))(function(resolve, reject) {
        var fulfilled = function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        };
        var rejected = function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        };
        var step = function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        };
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var SESSA = "SESSa";
var SESSR = "SESSr";
var SESSS = "SESSs";
var yearInSeconds = 365 * 24 * 60 * 60;
export var getIdentityClientURL = function(redirectURL) {
    var host = process.env.NEXT_PUBLIC_BIOCENTURY_IDENTITY_URL;
    var redirect = redirectURL ? "?redirect_uri=".concat(redirectURL) : "";
    return "".concat(host, "/").concat(redirect);
};
export var isAccessTokenExpired = function(accessToken) {
    var JWT = JWTDecode(accessToken);
    var now = DateTime.local().minus({
        minutes: 1
    });
    var expiration = DateTime.fromMillis(JWT.exp * 1000);
    return expiration <= now;
};
export var refreshAccessToken = function(context) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var _a, refreshToken, rawResponse, response;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    _ctx.prev = 1;
                    refreshToken = nookies.get(context).SESSr;
                    _ctx.next = 5;
                    return fetch("".concat((_a = process.env.BIOCENTURY_IDENTITY_API_URL) !== null && _a !== void 0 ? _a : process.env.NEXT_PUBLIC_BIOCENTURY_IDENTITY_API_URL, "/api/User/refresh-token"), {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(refreshToken)
                    });
                case 5:
                    rawResponse = _ctx.sent;
                    _ctx.next = 8;
                    return rawResponse.json();
                case 8:
                    response = _ctx.sent;
                    if (rawResponse.status !== 200) {
                        nookies.destroy(context, SESSA, {
                            path: "/",
                            domain: process.env.BIOCENTURY_DOMAIN
                        });
                        nookies.destroy(context, SESSR, {
                            path: "/",
                            domain: process.env.BIOCENTURY_DOMAIN
                        });
                    }
                    if (!(response && response.data.accessToken)) {
                        _ctx.next = 14;
                        break;
                    }
                    nookies.set(context, SESSA, response.accessToken, {
                        domain: process.env.BIOCENTURY_DOMAIN,
                        maxAge: 2 * yearInSeconds,
                        path: "/"
                    });
                    nookies.set(context, SESSR, response.data.refreshToken, {
                        domain: process.env.BIOCENTURY_DOMAIN,
                        maxAge: 2 * yearInSeconds,
                        path: "/"
                    });
                    return _ctx.abrupt("return", response.data.accessToken);
                case 14:
                    _ctx.next = 19;
                    break;
                case 16:
                    _ctx.prev = 16;
                    _ctx.t0 = _ctx["catch"](1);
                    console.error("Error refreshing access token - ", _ctx.t0);
                case 19:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                16
            ]
        ]);
    }));
};
export var logout = function() {
    destroyCookie(null, SESSA, {
        path: "/",
        domain: process.env.NEXT_PUBLIC_BIOCENTURY_DOMAIN
    });
    destroyCookie(null, SESSR, {
        path: "/",
        domain: process.env.NEXT_PUBLIC_BIOCENTURY_DOMAIN
    });
    destroyCookie(null, SESSS, {
        path: "/",
        domain: process.env.NEXT_PUBLIC_BIOCENTURY_DOMAIN
    });
};
